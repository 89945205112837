/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.lac-notification-sticky {
  position: relative;
  z-index: 999;
}

.lac-notification-sticky .lac-notification {
  background-color: #373330;
  color: #fff;
  font-size: 16px;
}

.lac-notification-sticky .lac-notification .lac-notification-icon {
  float: left;
  margin: 10px 25px 10px 10px;
}

.lac-notification-sticky .lac-notification .lac-notification-icon .logo-img {
  display: block;
}

.lac-notification-sticky .lac-notification .lac-notification-text {
  float: left;
  padding: 15px 0;
}

.lac-notification-sticky .lac-notification .lac-notification-links {
  float: right;
  padding: 15px 0;
}

.lac-notification-sticky .lac-notification .lac-notification-links a {
  color: #fff;
  font-size: 14px;
}

.lac-notification-sticky .lac-notification .lac-notification-links .lac-notification-close-link:after {
  background: url("../Magento_LoginAsCustomerFrontendUi/images/close.svg");
  content: ' ';
  display: inline-block;
  height: 12px;
  margin-left: 5px;
  vertical-align: middle;
  width: 12px;
}

@media screen and (max-width: 991.98px) {
  .lac-notification {
    padding: 5px 0;
  }
  .lac-notification .lac-notification-icon {
    display: none;
  }
  .lac-notification .lac-notification-text,
  .lac-notification .lac-notification-links {
    float: none;
    padding: 5px 0;
    text-align: center;
  }
}
